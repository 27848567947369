<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    page: {
        title: "BUAT SURAT TUGAS TEMPAT UJI KOMPETENSI",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "BUAT SURAT TUGAS TEMPAT UJI KOMPETENSI",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "BUAT SURAT TUGAS TEMPAT UJI KOMPETENSI",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],
            data_penggunaan: {
                penggunaan_nomor: "",
                penggunaan_tanggal: "",
                perihal: "",
                skema_code: "",
                skema_name: "",
                status_nama: "",
            },
            data_verifikasi: [],

            //modal
            showModalSimpan: false,
            form_surat_tugas: {
                tanggal_surat_tugas: new Date().toISOString().slice(0, 10),
                nomor_surat_tugas: null,
                perihal: "Surat Tugas Verifikasi TUK",
                pra_asesmen_selected: null,
                surat_penanganan: null,
                nomor_jadwal_sertifikasi: null,
                keterangan: null,
                urutan_nomor: null,
                pendaftaran_id: null,
                skema_sertifikasi: null,
                pendaftaran_nomor: null,
                penggunaan_id: null,
                verifikasi_id: null,
                tuk: null,
            },
            next_nomor: null,
            meta_asesor: [],
            optionsPraAsesmen: [],

            PreviewPDF: null,
        };
    },
    mounted() {
        this.getNomorVerifikasi();
        this.getJadwalPraAsesmen();
    },
    methods: {
        getJadwalPraAsesmen() {
            let self = this;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pra-asesmen-final", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    console.log(response_data.meta.code);
                    if (response_data.meta.code == 200) {
                        self.optionsPraAsesmen = response_data_fix.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },

        getNomorVerifikasi() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/get-nomor-verifikasi-tuk", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    console.log(currentDate);
                    self.form_surat_tugas.nomor_surat_tugas = next_nomor + "/LSP/TUK/" + self.convertToRoman(currentDate.getMonth()) + "/" + currentDate.getFullYear();
                    self.form_surat_tugas.urutan_nomor = next_nomor;
                    Swal.close();
                })
                .catch((e) => {
                    console.log(e);
                    Swal.close();
                });
        },

        storeVerifikasiTUK(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            // status default draft
            var send_status = "d8f0c064-4b94-11ee-913b-0242ac110006";
            if (status == "ajukan") {
                // if status ajuka = status proses Menunggu Persetujuan Manajer Sertifikasi LSP
                send_status = "fa4e689c-4b94-11ee-913b-0242ac110006";
            }
            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/store-verifikasi-tuk",
                data: {
                    tanggal_surat_tugas: self.form_surat_tugas.tanggal_surat_tugas,
                    nomor_surat_tugas: self.form_surat_tugas.nomor_surat_tugas,
                    perihal: self.form_surat_tugas.perihal,
                    pra_asesmen: JSON.stringify(self.form_surat_tugas.pra_asesmen_selected),
                    verifikasi_id: self.data_verifikasi?.id,
                    status_id: send_status,
                    urutan_nomor: self.form_surat_tugas?.urutan_nomor,
                    tuk: JSON.stringify(self.form_surat_tugas?.tuk),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.data_verifikasi = response_data_fix.data_surat_verifikasi.verifikasi;
                        console.log();
                        if (status == "generateSuratTugas") {
                            self.PreviewPDF = response_data_fix.pdf;
                            Swal.close();
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Anda akan segera diarahkan ke daftar verifikasi tuk",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    self.$router.push({ name: "all-verifikasi_tuk" });
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>FORMULIR SURAT TUGAS VERIFIKASI TEMPAT UJI KOMPETENSI</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Tugas</label>
                                                <input type="date" name="" id="" class="form-control" v-model="form_surat_tugas.tanggal_surat_tugas" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Surat Tugas</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_surat_tugas.nomor_surat_tugas" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_surat_tugas.perihal" disabled />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Jadwal Pra Asesmen</label>
                                                <v-select :options="optionsPraAsesmen" label="nomor_pelaksanaan" v-model="form_surat_tugas.pra_asesmen_selected" placeholder="Pilih Jadwal Pra Asesmen"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6" v-if="form_surat_tugas.pra_asesmen_selected">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tempat Uji Kompetensi</label>
                                                <v-select :options="form_surat_tugas?.pra_asesmen_selected?.daftar_tuk_jadwal" label="tuk_nama" v-model="form_surat_tugas.tuk" placeholder="Pilih Tempat Uji Kompetensi"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="form_surat_tugas.pra_asesmen_selected">
                                        <div class="col-md-12">
                                            <table class="table table-bordered table-striped my-3">
                                                <tbody>
                                                    <tr>
                                                        <th>No. Jadwal Sertifikasi</th>
                                                        <td>{{ form_surat_tugas.pra_asesmen_selected.nomor_pelaksanaan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Skema</th>
                                                        <td>{{ form_surat_tugas.pra_asesmen_selected.skema_nama }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-end">
                                            <button class="btn btn-primary" v-on:click="storeVerifikasiTUK('generateSuratTugas')"><i class="fas fa-save"></i> Generate Surat Tugas Verifikasi TUK</button>
                                        </div>
                                    </div>
                                    <div class="row" v-if="PreviewPDF">
                                        <h3>Preview Surat Tugas Verifikasi TUK</h3>
                                        <div class="row p-4">
                                            <div class="card" style="height: 400px; outline-style: outset">
                                                <div class="card-body">
                                                    <iframe :src="PreviewPDF" frameborder="0" style="width: 100%; height: 100%"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="PreviewPDF">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <button v-on:click="storeVerifikasiTUK('draft')" class="btn btn-warning" type="button"><i class="fas fa-edit"></i> Simpan Draft</button>
                                        <b-button v-on:click="storeVerifikasiTUK('ajukan')" type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
    <!-- <b-modal v-model="showModalSimpan" title="Konfigurasi Atasan" title-class="text-black font-18" body-class="p-3"
        size="md" hide-footer>
        <div class="row">
            <form @submit.prevent="StoreData">
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Sertifikasi</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Sertifikasi</option>
                        <option value="">Manajer Sertifikasi 1</option>
                        <option value="">Manajer Sertifikasi 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Pelatihan</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Pelatihan</option>
                        <option value="">Manajer Pelatihan 1</option>
                        <option value="">Manajer Pelatihan 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Ketua TUK</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Ketua TUK</option>
                        <option value="">Ketua TUK 1</option>
                        <option value="">Ketua TUK 2</option>
                    </select>
                </div>

                <div class="col-md-12 text-end mt-3">
                    <button class="btn btn-primary" type="submit"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal> -->
</template>
